import zsombor from "resources/images/horvath_zsombor-150x150.jpg";
import zsolt from "resources/images/horvath_zsolt-150x150.jpg";

export default function Doctor() {
  return (
    <div className="quickCall">
      <div className="callTheDoc">
        <a href="tel:0036309798436">
          <img src={zsombor} alt="Dr. Horváth Zsombor" width="50" height="50" />
          <span className="name">Dr. Horváth Zsombor</span>
          <span className="number">+36 (30) 979-8436</span>
        </a>
      </div>
    </div>
  );
}
